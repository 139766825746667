import { Row, Col } from 'reactstrap';
import React from 'react';
import Container from '../../../../components/Common/Container';

const GalleryContent = () => {
  return (
    <>
      <Row className="white-bg pt-3 pb-3">
        <Col md={8} className="d-flex flex-column text-left">
          <h2>Fotogalerias</h2>
        </Col>
      </Row>
      <Container />
    </>
  );
}

export default GalleryContent;
